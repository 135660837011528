import { BaseElement, html, css } from 'Components';
import Reveal from 'reveal.js';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';
import RevealNotes from 'reveal.js/plugin/notes/notes.esm.js';
import RevealHighlight from 'reveal.js/plugin/highlight/highlight.esm.js';
import RevealZoom from 'reveal.js/plugin/zoom/zoom.esm.js';
import App from 'App';
import { Sleep } from 'Utils';

class RevealPrez extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          display: block;
          width: 100%;
          height: 70vh;
        }

        :host(.fullscreen) {
          height:100vh;
        }

        .reveal {
          font-size:3em;
        }

        .reveal_overview {
          height: 100%;
          font-size:3em;
        }

        .reveal .slides {
          display:flex;
          width:95vw !important;
          height:90% !important;
        }

        pre.code-wrapper {
          text-align:left;
          font-size:20px;
        }

        section {
          height:100%;
          width:100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        apex-chart {
          flex:1;
        }

      `
    ]
  }

  static get properties() {
    return {
      content: { type: String },
    }
  }

  constructor() {
    super();
    this.isDarkTheme = false;
    //this.resizeCharts = this.resizeCharts.bind(this);
  }

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('content')) {
      this.revealInstance.destroy();
      this.slidesElement.innerHTML = this.content;
      this.createInstance();
    }
  }

  /*
  resizeCharts() {
    clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      const charts = this.qs('apex-chart', true);
      const cheight = Math.floor(window.innerHeight - 420);
      const cwidth = Math.floor(window.innerWidth - 80);
      for (const chart of charts) {
        chart.height = `${cheight}px`;
        chart.width = `${cwidth}px`;
      }
    }, 100);
  }
  */

  _onThemeChange() {
    this.requestUpdate();
  }

  toggleFullscreen() {
    const revealElement = this.shadowRoot.querySelector('.reveal');
    if (!document.fullscreenElement) {
      revealElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  }

  resize(fullscreen) {
    this.classList.toggle('fullscreen', fullscreen);
    this.revealInstance.layout();
  }

  async createInstance() {
    this.revealInstance = new Reveal(this.revealElement, {
      embedded: false,
      keyboardCondition: 'focused', // only react to keys when focused
    });

    this.revealInstance.initialize({
      // Configuration options
      plugins: [ Markdown, RevealNotes, RevealHighlight, RevealZoom ],
      controls: true,
      controlsTutorial: true,
      progress: true,
      width: '100%',
      height: '100%',
      margin:0.04,
      //margin:0
      minScale: 0.2,
      maxScale: 2.0,
      slideNumber: false,
      history: false,
      overview: true,
      touch: true,
      loop: false,
      help: true,
      embedded: false,
      viewDistance: 4,
      navigationMode: 'grid',
      mouseWheel: false,
      center: true,
      transition: 'convex', // none/fade/slide/convex/concave/zoom,
    });

    /*
    setTimeout(() => {
      //window.dispatchEvent(new Event('resize'));
      this.revealInstance.layout();
    }, 1);
    */

  }

  setSlides(content) {
    this.content = content;
  }

  async pushSlide(slide, go = true) {
    this.slidesElement.innerHTML+=slide;
    await this.revealInstance.sync();
    await Sleep(100);
    if (go) {
      this.revealInstance.slide(this.revealInstance.getTotalSlides());
    }
    return this.slidesElement;
  }

  async firstUpdated() {
    super.firstUpdated();
    this.revealElement = this.shadowRoot.querySelector('.reveal');
    this.slidesElement = this.shadowRoot.querySelector('.slides');
    this.slidesElement.innerHTML = this.innerHTML;
    await this.createInstance();

    // THIS IS NEEDED
    await Sleep(1);
    this.revealInstance.layout();
  }

  render() {
    return html`
      <link rel="stylesheet" href="assets/reveal/5.1.0/monokai.css"/>
      <link rel="stylesheet" href="assets/reveal/5.1.0/reveal.min.css"/>
      ${App.config.isDark 
        ? html`<link rel="stylesheet" href="assets/reveal/5.1.0/black.min.css"/>`
        : html`<link rel="stylesheet" href="assets/reveal/5.1.0/white.min.css"/>`
      }

      <style type="text/css">
        .shadow {
          filter:drop-shadow(0 0 10px rgba(0,0,0,0.5));
        }

        .reveals .controls {
          bottom:0px;
        }

      </style>
      <div class="reveal reveal-viewport">
        <div class="slides"></div>
      </div>
    `;
  }
}

customElements.define('reveal-prez', RevealPrez);