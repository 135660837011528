import { BaseElement, html, css } from 'Components';
import { UrlParams, Sleep } from 'Utils';

class TabGroup extends BaseElement {

  static get styles() {
    return [
      super.styles,
      css`
        sl-tab-group[size="small"] sl-tab::part(base) {
          font-size:0.8em;
          padding: 8px;
        }

        sl-tab-group[size="medium"] sl-tab::part(base) {
          font-size:1em;
        }

        sl-tab-group[size="medium"] sl-tab::part(base) {
          font-size:0.9em;
          padding: 8px;
        }

        sl-tab-group[size="medium"] sl-tab::part(base) {
          font-size:0.9em;
        }

        sl-tab-group::part(body) {
          overflow:hidden;
        }

        sl-tab-group {
          position:relative;
        }
      `
    ]
  }

  static get properties() {
    return {
      placement: { type: String },
      level: { type: String },
      size: { type: String },
    }
  }

  constructor() { 
    super();
    this.placement = 'top';
    this.level = 't';
    this.allowedTabs = [];
    this.tabs = {};
    this.size = '';
    this.onLocationChanged = this.onLocationChanged.bind(this)
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('vaadin-router-location-changed', this.onLocationChanged);
  }

  onLocationChanged() {
    const tabId = UrlParams.get(this.urlParam);
    if (!tabId) return;
    this.slTabGroup.show(tabId);
  }

  /*
  hiddenCallback() {
    super.hiddenCallback();
    //console.log('drop', this.urlParam); 
    UrlParams.del(this.urlParam);
  }
  */

  disconnectCallback() {
    super.disconnectCallback();
    //console.log('drop', this.urlParam); 
    UrlParams.del(this.urlParam);
  }

  async firstUpdated() {
    super.firstUpdated();
    this.urlParam = this.level;

    const navSlot = Array.from(this.children).filter(child => child.tagName.toLowerCase() === 'sl-tab');
    const panelSlot = Array.from(this.children).filter(child => child.tagName.toLowerCase() === 'sl-tab-panel');
    this.slTabGroup = this.querySelector('sl-tab-group');

    navSlot.forEach((el) => {
      this.slTabGroup.appendChild(el);
      el.addEventListener('click',() => {
        UrlParams.set(this.urlParam, el.getAttribute('panel'));
      });
    });

    let tabId;
    panelSlot.forEach((el) => {
      this.slTabGroup.appendChild(el);
      tabId = el.getAttribute('name');
      if (!tabId) {
        console.warn('TabGroup', 'missing tab level attribut', el);
      }
      this.allowedTabs.push(tabId);
      this.tabs[tabId] = el;
    });

    const t = UrlParams.get(this.urlParam) || this.allowedTabs[0];

    if (t) {
      if (t !== 'undefined') {
        await Sleep(1);
        this.slTabGroup.show(t);
      }
    }
  }

  onTabShow(ev) {
    const firstInput = this.tabs[ev.detail.name]?.querySelector('sl-input[type="text"]:not(.hidden)');
    if (firstInput) { setTimeout(() => { if (firstInput) firstInput.focus(); }, 0); }
  }


  render() {
    return html`
      <style>${this.constructor.styles}</style>
      <sl-tab-group placement="${this.placement}" size="${this.size}"  @sl-tab-show=${this.onTabShow}></sl-tab-group>
    `;
  }

  createRenderRoot() {
    return this;
  }

}


customElements.define('tab-group', TabGroup);