import { BaseElement, html, css } from 'Components';
import { Fetcher, Lang, Sleep } from 'Utils';

class SelectMultiple extends BaseElement {
  static get styles() {
    return css`
      :host {
        display: block;
        width:100%;
      }

      sl-option::part(base) {
        font-size:1em;
        font-family:Calibri;
        padding:3px;
      }
    `
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      items: { type: Array, reflect:true },
      name: { type: String },
      size: { type: String },
      values: { type: String, reflect:true },
      placeholder: { type: String },
      multiple: { type: Boolean },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            no_item:'No item available, please add some.',
          },
        },
        french:{
          translation: {
            no_item:'Aucun élement disponible, veuillez en ajouter.',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this._logEnable = false;
    this.apiEndpoint = null; // private/admin/items
    this.loading = true;
    this.name ='';
    this.size = 'medium';
    this.itemTitleField = 'title';
    this.placeholder = '';
    this.items = [];
    this.value = [];
    this.multiple = false;
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('items') || changedProperties.has('value')) {
      this._log.debug('updated: is array', Array.isArray(this.items), this.items);
      let value = '';
      if (!this.items || !this.items.length) {
        await this.updateComplete;
        this.value = '';
        this.requestUpdate();
        return;
      }

      for (const v of this.items) {
        value+=v._id.toString() + ' ';
      }
      
      await this.updateComplete;
      this.value = value.trim();
      this.requestUpdate();
    }
  }
  
  sortLoadedItems(items) {
    if (!this.itemTitleField) return items;
    if (!items || !items.length) return [];
    /*
    items.sort((a, b) => {
      if (Lang.lookup(a, this.itemTitleField) < Lang.lookup(b, this.itemTitleField)) return -1;
      if (Lang.lookup(a, this.itemTitleField) > Lang.lookup(b, this.itemTitleField)) return 1;
      return 0;
    });
    */
    return items;
  }


  async visibleCallback() {
    super.visibleCallback();
    await this._loadItems();

    // this hack is needed to refresh input content (when changing app language)
    await this.updateComplete;
    await Sleep(1);
    this.shadowRoot.querySelector('sl-select').show();
    this.shadowRoot.querySelector('sl-select').hide();
  }

  async _loadItems() {
    this.loading = true;
    const response = await Fetcher.get(this.apiEndpoint);
    const loadedItems = response?.data;
    this.loadedItems = this.sortLoadedItems(loadedItems);
    this.displayedItems = JSON.parse(JSON.stringify(this.loadedItems));
    this.loading = false;
    this.select = this.select || this.shadowRoot.querySelector('sl-select');
  }

  async sendEvent() {
    this.items = this.records;
    this.dispatchEvent(new CustomEvent('items-changed', { detail: this.records }));
  }  

  _onChange(ev) {
    this.records = [];
    for (const value of ev.target.value) {
      this.records.push(this.loadedItems.find((item) => item._id === value));
    }
    this.sendEvent();
    this.requestUpdate();
  }

  displayItems() {
    if (!this.loadedItems?.length) return '';
    return html`
      ${this.loadedItems.map((item) => html`
        <sl-option value="${item._id.toString()}">
          ${this.displayAsTags
            ? html`<m-tag variant="primary" size="${this.size}" .tag=${item}>${Lang.lookup(item, 'title')}</m-tag>` 
            //? html`<sl-tag variant="primary" size"small" .tag=${item}>${Lang.lookup(item, 'title')}</m-tag>` 
            : Lang.lookup(item, 'title')
          }
        </sl-option>
      `)}
    `;
  }

  render() {
    // Do not remove that, because select initiale value will not work without it
    if (!this.displayedItems) return html`<sl-select size="${this.size}" placeholder="..."></sl-select>`;
    if (!this.displayedItems.length) return html`${this._tl('no_item')}`;
    
    return html`
      <sl-select placement="bottom" placeholder="${this.placeholder}"
        size="${this.size}"
        @sl-change=${this._onChange}
        value=${this.value}
        ${this.name ? `name="${this.name}"` : ''}
        multiple
        clearable
      >${this.displayItems()}
      </sl-select>
    `;
  }
}

export default SelectMultiple;