import { BaseElement, html, css } from 'Components';

class ModaDrawer extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          position: fixed;
          opacity: 0;
          display:flex;
          justify-content:center;
          align-items:center;
          transition: backdrop-filter 2s, background-color 0.2s, opacity 0.3s;
          backdrop-filter: blur(0px) grayscale(0%);
          visibility: hidden;
          font-family: Calibri, sans-serif;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1900;
        }

        :host(.visible) {
          opacity:1;         
        }

        :host(.visible.modal) {
          visibility: visible;
          opacity:1;
          backdrop-filter: blur(0px)  grayscale(100%);
          background-color:#222222aa;
        }

        .container .popup {
          position:fixed;
          top:0px;
          right:0px;
          bottom:0px;
          width:50vw;
          min-width:648px;
          z-index:2000;
          background-color:var(--page-background-color);
          box-shadow:0 4px 8px rgba(0,0,0,0.5);
          border-radius:5px;
          max-height: 100vh;
          display:flex;
          flex-direction: column;
          padding-right:10px;
          /*
          overflow-y: auto;
          overflow-x: hidden;
          */
        }

        .container .popup.fullscreen {
          width:100vw;
          height:100vh;
          background-color:var(--page-background-color);
        }

        .container .popup.large {
          width:80vw;
          height:100vh;
          background-color:var(--page-background-color);
        }

        @keyframes fadeIn {
          from {
            opacity: 0.2;
            transform: translateX(100%);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
            transform: translateX(0);
          }
          to {
            opacity: 0;
            transform: translateX(100%);
          }
        }

        .fade-in {
          animation: fadeIn 0.2s ease-out;
        }

        .fade-out {
          animation: fadeOut 0.2s ease-out;
        }

        :host(.visible) .container .popup {
          visibility:visible;
          transition: backdrop-filter 2s, background-color 0.3s;
        }

        .header {
          display:flex;
          justify-content:space-between;
          align-items:center;
          font-family:Calibri;
          text-transform:uppercase;
          font-weight:bold;
          padding:15px;
          padding-top:10px;
          padding-bottom:10px;
          gap:20px;
          font-size:1.2em;
        }

        .header span {
          white-space:nowrap;
        }

        m-icon {
          cursor:pointer;
        }

        .xcontent {
          padding:10px;
          height:100%;
          /*overflow-y: auto;*/
          overflow-y: hidden;
          display:block;
        }

        .xcontent.fullscreen {
          padding:0px;
          height:100vh;
        }

        .xcontent.large {
        }

        .buttons {
          padding:10px;
          padding-left:20px;
          padding-right:20px;
          display:flex;
          justify-content:center;
          flex-direction:row-reverse;
          gap:20px;
          border-top:1px solid var(--sl-color-neutral-300);
        }

        /* Téléphones portables (petits appareils) - iPhone 8 et similaires */
        @media (max-width: 670px) {
          .container .popup {
            width: 99vw;
            min-width:initial;
          }
        }

        /* Téléphones portables (grands appareils) - Entre iPhone 8 et tablettes */
        @media (min-width: 670px) and (max-width: 900px) {
        }

        /* Tablettes en mode portrait */
        @media (min-width: 768px) and (max-width: 1024px) {
        }

        /* Tablettes en mode paysage */
        @media (min-width: 1024px) and (max-width: 1200px) {
        }
        `
    ];
  }

  static get properties() {
    return {
      open: { type: Boolean },
      large: { type: Boolean },
      noHeader: { type: Boolean },
      modal: { type: Boolean },
      label: { type: String },
      fullscreen: { type: Boolean },
      hideButtons: { type: Boolean },
      name: { type: String },
      pendingChanges: { type: Boolean },
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            confirm_title:'Confirmation requested',
            unsaved_changes:'You have unsaved changes',
            really_close:'Do you really want to close ?',
            cancel:'Continue changes',
            close:'Close without saving'
          },
        },
        french:{
          translation: {
            confirm_title:'Confirmation demandée',
            unsaved_changes:'Vous avez des modifications non enregistrées',
            really_close:'Voulez-vous vraiment fermer ?',
            cancel:'Continuer les modifications',
            close:'Fermer sans enregistrer'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.open = false;
    this.modal = false;
    this.label = '';
    this.noHeader = false;
    this.fullscreen = false;
    this.large = false;
    this.hideButtons = false;
    this.pendingChanges = false;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.adjustMarginRight);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.adjustMarginRight);
  }

  firstUpdated() {
    super.firstUpdated();
    this.initButtons();
  }

  initButtons() {
    this.buttons = this.querySelectorAll('sl-button');
    for (const button of this.buttons) {
      //console.log('initButtons', button);
      const attrClose = button.getAttribute('close');
      if (attrClose === 'true') {
        //console.log('button', button.innerHTML, 'will hide');
        button.addEventListener('click', this.hide.bind(this));
      } else if (attrClose === 'false') {
        //console.log('button', button.innerHTML, 'will not close');
      } else {
        const originalClickHandler = button.onclick;
        if (typeof originalClickHandler === 'function') {
          //console.log('button', button.innerHTML, 'has a click handler');
          button.addEventListener('click', async (event) => {
            await originalClickHandler.call(button, event);
            await this.hide();
          });
        }
      }
    }
  }

  loadingButtons() {
    this.buttons = this.buttons || this.querySelectorAll('sl-button');
    for (const button of this.buttons) {
      button.disabled = true;
      if (button.getAttribute('variant') === 'primary') {
        button.loading = true;
      }
    }
  }

  disableButtons() {
    this.buttons = this.buttons || this.querySelectorAll('sl-button');
    for (const button of this.buttons) {
      if (button.getAttribute('variant') === 'primary') {
        button.disabled = true;
      }
    }
  }

  resetButtons() {
    this.buttons = this.buttons || this.querySelectorAll('sl-button');
    for (const button of this.buttons) {
      button.disabled = false;
      button.loading = false;
    }
  }

  updated(changedProperties) {
    const popup = this.shadowRoot.querySelector('.popup');
    if (changedProperties.has('open')) {
      if (this.open) {
        if (this.modal) {
          window.dispatchEvent(new CustomEvent('overlay-show'));
          this.classList.add('visible');
          this.classList.add('modal');
        } else {
          this.classList.add('visible');
          //this.bodyScroll = document.body.style.overflow;
          //document.body.style.overflow = 'auto';
        }
        
        popup.classList.add('fade-in');
        popup.addEventListener('animationend', () => {
          popup.classList.remove('fade-in');
        }, { once: true });
        
      } else {  
        if (this.modal) {
          window.dispatchEvent(new CustomEvent('overlay-hide'));
        }
        // restore scrollbar to body
        //document.body.style.overflow = this.bodyScroll;
        popup.classList.add('fade-out');
        popup.addEventListener('animationend', () => {
          this.classList.remove('visible');
          popup.classList.remove('fade-out');
        }, { once: true });
      }
    }
  }

  async show() {
    this.open = true;
  }

  async hide() {
    if (this.pendingChanges) {
      this.showPendingChanges();
      return;
    }

    this.open = false;
    this.dispatchEvent(new CustomEvent('onHide', {
      bubbles: true,
      composed: true
    }));
  }

  forceHide() {
    this.pendingChanges = false;
    this.hide();
  }

  showPendingChanges() {
    this.shadowRoot.querySelector('#modal-confirm-changes').show();
  }

  render() {
    return html`
      <div class="container">
        <div class="popup ${this.fullscreen ? 'fullscreen' : ''} ${this.large ? 'large' : ''}" ?open="${this.open}">
          <div class="header">
            <span>${this.label}</span>
            ${!this.modal ? html`<m-icon name="close" @click="${this.hide}"></m-icon>` : ''}
          </div>
          <hr-custom style="margin-left:5px"></hr-custom> 
          <div class="xcontent ${this.fullscreen ? 'fullscreen' : ''} ${this.large ? 'large' : ''}">
            <slot></slot>
          </div>
          ${this.hideButtons
            ? '' 
            : html`
              <div class="buttons">
                <slot name="bt1"></slot>
                <slot name="bt2"></slot>
                <slot name="bt3"></slot>
              </div>`
          }
        </div>
      </div>
      
      <modal-dialog id="modal-confirm-changes" label="${this._tl('confirm_title')}">
        ${this._tl('unsaved_changes')}.<br/>${this._tl('really_close')}
        <br/><br/>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" close="true" @click=${this.forceHide}>${this._tl('close')}</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('modal-drawer', ModaDrawer);